import { createTheme } from '@mui/material/styles';

// define wsib global style in this theme file
const wsibTheme = createTheme({
    palette: {
      primary: {
        main: "#0076bf"
      },
      secondary: {
        main: "#d90000"
      }
    },
    typography: {
      fontFamily: 'Muli',
      fontSize: 14,
      body1: {
        fontSize:14,
      },
      body2: {
        fontSize:14,
        fontWeight: 700
      },
      h1:{
        fontSize: 27,
        fontWeight: 700
      },
      h2:{
        fontSize: 27,
        fontWeight: 700
      },
      h3:{
        fontSize: 24,
        fontWeight: 700
      }
    },
    spacing: 10,
    components: {
      MuiButton: {
        defaultProps:{
          disableElevation: true,
        },
        styleOverrides: {
          root: {
            borderRadius: '2em',
            width: '9em',
            textTransform: 'none',
            border: '1px solid #0076bf',
            color: '#fff',
            backgroundColor: '#0076bf',
            "&:focus:active,&:focus-visible,&:focus-visible:hover":{
              border: '1px solid #0076bf',
              backgroundColor: '#0076BF',
              boxShadow:'0 0 0 2px #ffffff ,0 0 0px 5px #292929',
              outline: '2px dotted transparent',
              outlineoffset: '2px'
            },
            "&:hover" :{
              border: '1px solid #292929',
              color: 'white',
              outline: '2px dotted transparent #292929',
              backgroundColor: '#292929'
            }
          }
        }
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            color: '#333333',
            fontSize:'12px'
          }
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: '#333333',
            fontSize:'20px'
          }
        }
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontSize:'20px'
          },
          input: {
            fontSize:'15px'
          }
        }
      },
      MuiStepIcon: {
        styleOverrides: {
          root: {
            "&.Mui-active":{
            color: '#003359'
            },
            "&.Mui-completed":{
              color: '#003359'
            }
          }
        }
      },
      MuiLinearProgress: {
        styleOverrides: {
          bar: {
            backgroundColor: '#003359'
          }
        }
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
           color: '#333333',
           "&.Mui-focused":{
            color: '#333333'
            }
          },

        }
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            color: 'rgba(0, 0, 0, 1)',
            "&.Mui-focusVisible,&:hover,&:active ":{
              border: '1px solid #333333'
            }
          }
        }
      }
    }
  });

  export default wsibTheme;